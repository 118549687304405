import InvestmentsSidebar from "./InvestmentsSidebar";
import "./YourInvestments.scss";
import useYourInvestments from "../../../hooks/useYourInvestments";
import AnalyzeYourInvestment from "./AnalyzeYourInvestment/AnalyzeYourInvestment";
import PlanYourInvestment from "./PlanYourInvestment/PlanYourInvestment";
import { API, useModulesPermissions } from "@qualitas/modules-permissions";
import { useEffect } from "react";
import { FrownOutlined } from "@ant-design/icons";


const YourInvestments = (props: any) => {
  const {
    translations,
    userContactId,
    userIdInstitution,
    language,
    baseUrl,
    getAuthToken,
    showServiceFeedbackModal,
  } = props;

  const {
    selectedOption,
    handleInvestmentOption,
    setModulesPermitted,
    modulesPermitted,
    peips,
    sidebarOptions,
    setSelectedOption,
    institutions
  } = useYourInvestments({
    userContactId,
  });

  const modulesPermissionAPI = API({
    baseUrl,
    getAuthToken,
    showServiceFeedbackModal,
  });
  const { modulesPermissions, handleChangeContact, handleSelectApplication, loading, idContact } = useModulesPermissions({
    api: modulesPermissionAPI,
    investorPortalAccess: false
  });

  useEffect(() => {
    handleChangeContact(userContactId);
  }, []);

  useEffect(() => {
    if (idContact > 0) 
      handleSelectApplication(4);
  }, [idContact]);
  
  useEffect(() => {
    setModulesPermitted(
      modulesPermissions.map((x: any) => {
        if (x.idRol > 0 && x.idSystemMaster === 4) {
          if((x.moduleName === "Plan Your Investment/Plan Your Investment" || x.moduleName === "Analyze Your Investment/Analyze Your Investment")&& !selectedOption) {
            setSelectedOption(x.moduleName)
          }
          return x.moduleName
        };
      })
    );
  }, [modulesPermissions]);

  return (
    <div className="main-investments">
      <InvestmentsSidebar
        translations={translations}
        handleInvestmentOption={handleInvestmentOption}
        sidebarOptions={sidebarOptions}
        loading={loading}
        selectedOption={selectedOption}
        modulesPermitted={modulesPermitted}
      />
      {selectedOption === "Analyze Your Investment/Analyze Your Investment" && (
        <AnalyzeYourInvestment
          userIdInstitution={userIdInstitution}
          userPeips={peips}
          language={language}
          baseUrl={baseUrl}
          getAuthToken={getAuthToken}
          showServiceFeedbackModal={showServiceFeedbackModal}
        />
      )}
      {selectedOption === "Plan Your Investment/Plan Your Investment" && (
        <PlanYourInvestment
          userIdInstitution={userIdInstitution}
          language={language}
          baseUrl={baseUrl}
          getAuthToken={getAuthToken}
          showServiceFeedbackModal={showServiceFeedbackModal}
          institutions={institutions}
        />
      )}
      {!loading && !modulesPermitted.find((x: any)=> x === "Plan Your Investment/Plan Your Investment" || x ===  "Analyze Your Investment/Analyze Your Investment") && (
        <div className="no-permissions">
          <p>
            {translations.noPermissions}
            <br />
            <FrownOutlined style={{fontSize: 32, color: 'grey', marginTop: 15}} />
          </p>
          </div>
      )}
    </div>
  );
};

export default YourInvestments;
