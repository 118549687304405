import AnalysisAndReporting, { useAnalysisAndReporting, AnalysisAndReportingAPI } from '@qualitas/reporting';

import { IPlanYourInvestment } from './IPlanYourInvestment';

const PlanYourInvestment = ({
	userIdInstitution,
	language,
	baseUrl,
	institutions,
	getAuthToken,
	showServiceFeedbackModal,
}: IPlanYourInvestment) => {
	const api = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken: getAuthToken,
		showServiceFeedbackModal
	});
	const reportingProps = useAnalysisAndReporting({
		api,
		appName: 'Investor Portal',
		powerBIName: `${language === 'es' ? 'Planifica tu inversión' : 'Plan your investment'}`
	});

	return (
		<div className="reporting">
			{reportingProps.powerBIToken
				? <AnalysisAndReporting
					{...reportingProps}
					filters={[
						{
							$schema: "http://powerbi.com/product/schema#basic",
							target: {
							  table: "InstitutionName",
							  column: "InstitutionLong",
							},
							operator: "In",
							values: institutions,
						  },
						  {
							$schema: "http://powerbi.com/product/schema#basic",
							target: {
							  table: "vw_General_Inversores_Modificada2",
							  column: "InstitutionLong",
							},
							operator: "In",
							values: institutions,
						  },
					]}
				/>
				: null
			}
		</div>
	);
}

export default PlanYourInvestment;