import AnalysisAndReporting, { useAnalysisAndReporting, AnalysisAndReportingAPI } from '@qualitas/reporting';

import { IAnalyzeYourInvestment } from './IAnalyzeYourInvestment';

const AnalyzeYourInvestment = ({
	userIdInstitution,
	language,
	baseUrl,
	getAuthToken,
	userPeips,
	showServiceFeedbackModal,
}: IAnalyzeYourInvestment) => {

	const api = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken: getAuthToken,
		showServiceFeedbackModal
	});
	const reportingProps = useAnalysisAndReporting({
		api,
		appName: 'Investor Portal',
		powerBIName: `${language === 'es' ? 'Analiza tu inversión' : 'Analyze your investment'}`
	});

	return (
		<div className="reporting">
			{reportingProps.powerBIToken
				? <AnalysisAndReporting
					{...reportingProps}
					filters={[{
						$schema: "http://powerbi.com/product/schema#basic",
						target: {
							table: "12. Programa Qualitas",
							column: "F.Qualitas"
						},
						operator: "In",
						values: userPeips
					}]}
				/>
				: null
			}
		</div>
	);
}

export default AnalyzeYourInvestment;